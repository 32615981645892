import React from 'react'
import './PodcastBlogText.scss'
import { Grid} from "@mui/material";
import DownloadbtnText from '../../../staticcomponents/downloadbtn-text'
import green_arrow from '../../../assets/long blog arrow.png';
import Container from '@material-ui/core/Container';

export default function PodcastBlogText() {
  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
    <Grid container className="podcast-blog-text-cls">
        <Grid container className="section-with-blue-div">
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <div className="text-with-blue-div">
            <Grid item xl={7} lg={9} md={7} sm={8} xs={12}>
              <div className="text-with-blue-section">
            <img src={green_arrow} alt="green arrow" className="green-arrow"/>
            <div className="title">CORE/FLEX PULSE SURVEY</div>
            <div className="date">JUNE 2022</div>
            <p className="description">If there’s one thing the global pandemic has taught us, it’s that flexibility is key to success.</p>
            <div className="date">JUNE 2022</div>
            <p className="description">If there’s one thing the global pandemic has taught us, it’s that flexibility is key to success. </p>
            </div>
            </Grid>
            </div>
          </Grid>
        {/* <Grid item xl={5} lg={5} md={12} sm={12} xs={12} className="text-with-download-div">
        <DownloadbtnText/>
          </Grid> */}
          <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>     
          <div className="rich-text-para">   
              <p>
                Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well, many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over 200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily life.
              </p>
              <p>
                Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well, many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over 200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily life.
              </p>
          </div>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  )
}


