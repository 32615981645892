import React from "react";
import { Grid} from '@material-ui/core';
import download_arrow from '../../assets/long blog arrow.png';
import './downloadbtn-text.scss';
import {Link} from 'gatsby';
const DownloadbtnText = () => {
    return (
        <>
           <Grid className="text-with-download" item xl={8} lg={9} md={7} sm={8} xs={12}>
              <div className="title">title goes here</div>
              <p className="description">If there’s one thing the global pandemic has taught us, it’s that flexibility is key to success. A core/flex policy approach is becoming the number one trend in relocation for a reason: it provides ultimate</p>
              <div className="download-arrow-div">
              <img src={download_arrow} alt="download arrow" />              
              <Link to="#" className="download-text" tabindex="-1">download</Link>
              </div>
            </Grid>
        </>
    );
};

export default DownloadbtnText;
