import React from 'react'
import Layout from '../../staticcomponents/layout'
import './podcastblog.scss'
import PodcastHeader from './podcastheader'
import PodcastBlogText from './podcastblogtext'
import CategorySocialMedia from '../../staticcomponents/category-social media'
import AuthorDetails from '../../staticcomponents/author details'




export default function PodcastBlog() {
    return (
        <>
            <Layout>
            <div className='PodcastBlog_main'>
        <div className='bg-donuts-right'>
                <PodcastHeader />
                <PodcastBlogText/>
                </div>
                <div className='category-bottom-padding'>
                <CategorySocialMedia/>
                </div>
            {/* <div className='bg-capsule'> */}
            <AuthorDetails/>
            {/* </div> */}
            </div>
            </Layout>
        </>
    )
}