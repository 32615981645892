import React from "react";
import { Grid, Typography, Box, Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import ImageOne from '../../assets/sliderImg.png';
import './author details.scss'
import Container from '@material-ui/core/Container';
import Carousel from 'react-elastic-carousel';
export default function AuthorDetails() {
 return (
      <>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="author-main-cls">
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={2}
                  className="cols-alignment"
                >
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="author-img-txt">
                  <CardMedia  image={ImageOne} className="img-alignment" alt="Podcast thumbnail" />
                  {/* <img className='header-image' src={Podcast_thumbnail} alt="Podcast thumbnail" /> */}
                  <p className='name'> Daisy Jennett</p>
                  <p className='designation'> Vice President </p>
                  </Grid>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className="testimonialInfo">
                    <CardContent className="author-card-space">
                      <p className="powerby">
                      Posted By
                      </p>
                      <p className="description">
                      <p>As Vice President, Daisy has global ownership for a highly successful portfolio of Fortune 100 multinational companies with teams based in the US, UK, Singapore, Hong Kong, and India. Her portfolio of clients is well served by her experience in designing and deploying global mobility solutions throughout the world. Daisy has served within various Cartus departments including domestic and international account management, operations, and international compensation.</p>
                      </p>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
             </Grid>
        </Grid>
        </Container>
      </>
    );
  }